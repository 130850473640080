<template>

    <v-dialog
        v-model="modal"
        persistent
        max-width="600"
    >

        <v-card>

            <v-toolbar color="primary" dark flat>

                <v-toolbar-title class="font-weight-medium">
                    <v-icon>mdi-cellphone-sound</v-icon>
                    Sign with Authenticate
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="closeDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <span>Close Dialog</span>
                </v-tooltip>

            </v-toolbar>

            <v-container class="px-4">

                <v-row no-gutters>

                    <v-col cols="6">

                        <p class="title text--primary">Sign PDF with app</p>

                        <template>

                            <p class="">
                                Open the notification you received on your phone.
                                Click on the message you just received and confirm this with your PIN
                            </p>

                            <v-alert type="info" dense text color="primary" class="mr-2">
                                Didn't receive a notification? Open the app and refresh your screen.
                            </v-alert>

                        </template>

                        <v-list two-line class="pt-0 mt-n4" subheader>
                            <v-subheader class="font-weight-medium text-body-1 px-0">Transaction Details</v-subheader>

                            <v-list-item class="px-0">
                                <v-list-item-content class="pt-0">
                                    <v-list-item-title>{{ pdf.name }}</v-list-item-title>
                                    <v-list-item-subtitle>Document Name</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                        </v-list>

                    </v-col>

                    <v-col cols="6">
                        <v-container style="background: #ddd;">
                            <v-img
                                :src="imageBlob"
                                contain
                                height="auto"
                            ></v-img>
                        </v-container>

                    </v-col>

                </v-row>

                <v-row no-gutters class="mt-4 mb-2">

                    <v-row v-if="transactionStatus === 2" no-gutters align="center">
                        <v-alert type="success" dense text class="mb-0">
                            Document signed successfully!
                            <template v-slot:append>
                                <v-btn text class="my-n2" color="green" @click="closeDialog">
                                    Finish
                                </v-btn>
                            </template>
                        </v-alert>

                        <v-spacer></v-spacer>

                        <template v-if="signedContent">
                            <v-btn color="primary" class="white--text" @click="onDownloadClicked">
                                Download PDF
                            </v-btn>
                        </template>
                        <template v-else>
                            <v-chip
                                class="ml-1 font-weight-medium"
                                text-color="white"
                                small
                                color="primary"
                            >
                                <v-progress-circular
                                    size="15"
                                    class="mr-1"
                                    indeterminate
                                    color="white"
                                ></v-progress-circular>
                                Loading document
                            </v-chip>
                        </template>
                    </v-row>

                    <v-alert v-if="transactionStatus === 3" type="error" width="100%" dense text class="mb-0">
                        The sign request expired.
                        <template v-slot:append>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn :disabled="creatingTransaction" v-on="on" color="red" icon small
                                           @click="restartTransaction">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                </template>
                                <span>Try again</span>
                            </v-tooltip>
                        </template>
                    </v-alert>

                    <v-alert v-if="transactionStatus === 5" type="error" width="100%" dense text class="mb-0">
                        You rejected the sign request.
                        <template v-slot:append>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn :disabled="creatingTransaction" v-on="on" color="red" icon small
                                           @click="restartTransaction">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                </template>
                                <span>Try again</span>
                            </v-tooltip>
                        </template>
                    </v-alert>

                    <v-alert v-if="transactionStatus === 4 || transactionStatus > 5" type="error" width="100%" dense text class="mb-0">
                        The sign request failed
                        <template v-slot:append>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn :disabled="creatingTransaction" v-on="on" color="red" icon small
                                           @click="restartTransaction">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                </template>
                                <span>Try again</span>
                            </v-tooltip>
                        </template>
                    </v-alert>
                </v-row>

            </v-container>

            <v-progress-linear
                v-if="(transactionStatus === 0 || transactionStatus === 1) && !creatingTransaction"
                indeterminate
                color="primary"
            ></v-progress-linear>

            <v-progress-linear v-if="creatingTransaction" indeterminate></v-progress-linear>

        </v-card>

    </v-dialog>

</template>

<script>
import Utils from "@/utils/utils";

export default {
    name: "SignPdfDialog",

    props: {

        creatingTransaction: {
            type: Boolean,
            required: true
        },

        pdf: {
            type: Object,
            required: true
        },

        signedContent: {
            type: String,
            required: false
        },

        transactionStatus: {
            type: Number,
            required: true
        },

    },

    data: () => ({
        imageBlob: "",
        restartingTransaction: false,
        modal: true
    }),

    methods: {

        closeDialog() {
            this.$emit("close");
        },

        onDownloadClicked() {
            const downloadElement = document.createElement("a");
            downloadElement.setAttribute(
                "href",
                "data:application/octet-stream;base64," + encodeURIComponent(this.signedContent)
            );

            let strippedName = this.pdf.name.split(".pdf")[0];
            let signedPDFName = strippedName + "-signed.pdf"

            downloadElement.setAttribute("download", signedPDFName);
            downloadElement.style.display = "none";

            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement);
        },

        restartTransaction() {
            this.$emit("restart");
        },

    },

    created() {
        let blob = Utils.b64ToBlob(this.pdf.transactionImage);
        this.imageBlob = URL.createObjectURL(blob);
    }

}
</script>

<style scoped>

</style>