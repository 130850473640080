import axios from "axios";
import SessionManager from "@/utils/session";
import BindingManager from "@/utils/api/binding";

const SessionAPIHandler = {

    communicator: Object,

    logOutRoutine: Function,

    init() {
        let API_URL = config.SESSION_API_URL;

        this.communicator = axios.create({
            baseURL: API_URL,
            timeout: 15000,
            withCredentials: true,
        });
    },

    handleError(error) {
        switch (error.response.status) {
            case 500:
                console.error("Internal Server Error!")
                return;
        }

        if (error.response.data.error) {
            switch (error.response.data.error.code) {
                case SessionAPI.ERRORS.NOT_LOGGED_IN:
                    this.logOutRoutine();
                    break;
                case SessionAPI.ERRORS.NO_ACTIVE_SESSION:
                case SessionAPI.ERRORS.INVALID_CSRF_TOKEN:
                    BindingManager.start().then();
                    break;
            }
        }
    },


    async get(endpoint, queryParams = null) {
        let header = {"X-CSRF-TOKEN": SessionManager.getCSRFToken()};

        try {
            const response = await this.communicator.get(endpoint, {
                params: queryParams,
                headers: header
            });

            if (response.status < 300) {
                SessionManager.setCSRFToken(response.headers["x-csrf-token"]);
            }

            return response.data;
        } catch (error) {
            this.handleError(error);
            throw error;
        }
    },

    async delete(endpoint, queryParams = null) {
        let header = {"X-CSRF-TOKEN": SessionManager.getCSRFToken()};

        try {
            const response = await this.communicator.delete(endpoint, {
                params: queryParams,
                headers: header
            });

            if (response.status < 300) {
                SessionManager.setCSRFToken(response.headers["x-csrf-token"]);
            }

            return response.data;
        } catch (error) {
            this.handleError(error);
            throw error;
        }
    },

    async post(endpoint, data) {
        let header = {"X-CSRF-TOKEN": SessionManager.getCSRFToken()};

        try {
            const response = await this.communicator.post(endpoint, data, {headers: header});

            if (response.status < 300) {
                SessionManager.setCSRFToken(response.headers["x-csrf-token"]);
            }

            return response.data;
        } catch (error) {
            this.handleError(error);
            throw error;
        }
    }

}

const SessionAPI = {

    ERRORS: {
        NOT_LOGGED_IN: 7,
        INVALID_CSRF_TOKEN: 10,
        NO_ACTIVE_SESSION: 12
    },

    LOGIN_STATUS: {
        CREATED: 0,
        IDENTIFYING: 1,
        IDENTIFIED: 2,
        LOGGING_IN: 3,
        LOGGED_IN: 4
    },

    init(routine) {
        SessionAPIHandler.logOutRoutine = routine;
    },

    async newLogin() {
        return await SessionAPIHandler.post("login/");
    },

    async getLoginStatus(uuid) {
        return await SessionAPIHandler.get(`login/${uuid}/`);
    },

    async getUsers() {
        return await SessionAPIHandler.get("user/");
    },

    async getUser(userID) {
        return await SessionAPIHandler.get("user/" + userID + "/");
    },

    async getAccessToken(userID) {
        return await SessionAPIHandler.get(`user/${userID}/access_token/`);
    },

    async relogin(userID) {
        return await SessionAPIHandler.post("user/" + userID + "/login/");
    },

    async logout(userID) {
        return await SessionAPIHandler.post("user/" + userID + "/logout/");
    },

    async remove(userID) {
        return await SessionAPIHandler.delete("user/" + userID + "/");
    },

}

SessionAPIHandler.init();

export default SessionAPI;