<template>

    <v-container height="100%" fluid class="grey lighten-4 px-0 pt-0" style="max-width: 1920px;">

        <v-img
            :src="require('../assets/ubiqu-phone.jpg')"
            height="300"
            max-width="100%"
        ></v-img>

        <!-- HEIGHT FILLER -->
        <v-row style="height: 150px;" no-gutters v-if="pdfLoaded">
        </v-row>

        <v-container>

            <!-- Content Card -->
            <v-row justify="center" class="grey lighten-4 mt-lg-2">

                <v-fade-transition>
                    <v-col cols="12" offset-md="4" offset-lg="3" v-show="showPDFSelector">
                        <p class="text-h4 font-weight-bold primary--text">Select PDF to continue</p>
                    </v-col>
                </v-fade-transition>

                <v-col cols="12" md="8" lg="9" class="py-0">

                    <!-- Select file card -->
                    <v-fade-transition>
                        <v-card id="selectFileCard" v-show="loaded && showPDFSelector" elevation="12" height="400"
                                class="pa-4">

                            <v-container style="border-style: dashed; height: 100%; border-color: gray" class="d-flex">

                                <v-row no-gutters justify="center" align="center">

                                    <!-- Button that is used to select a file -->
                                    <v-btn
                                        color="primary"
                                        :loading="isSelectingFile"
                                        rounded
                                        type="input"
                                        @click="onSelectFileClicked"
                                    >Select File
                                    </v-btn>

                                    <input
                                        accept="application/pdf"
                                        ref="hiddenFileUploader"
                                        class="d-none"
                                        type="file"
                                        @change="onFileChanged"
                                    >

                                </v-row>

                            </v-container>

                        </v-card>
                    </v-fade-transition>

                    <!-- PDF viewer card -->
                    <v-fade-transition>
                        <v-card
                            v-show="pdfLoaded" elevation="12" class="pa-4"
                            :class="$vuetify.breakpoint.xl ? 'pdfViewerCardLarge' : 'pdfViewerCardMedium'"
                        >
                            <v-card-title class="py-0 pr-0">
                                {{ pdf.name }}
                                <v-chip v-if="signedPDF" color="green" label small class="ml-2 white--text">
                                    <v-icon small left>mdi-certificate</v-icon>
                                    <span>Signed</span>
                                </v-chip>
                            </v-card-title>
                            <pdf-view-container
                                v-if="inputFile != null"
                                ref="pdfViewContainer"
                                :file="inputFile"
                                :read-only="signStepper >= 3 || submittingFile"
                                @rendered="onPdfRendered"
                                @set="onSignatureBoxSet"
                            ></pdf-view-container>
                        </v-card>
                    </v-fade-transition>

                </v-col>

                <!-- How to sign card -->
                <v-col v-if="pdfLoaded" cols="12" md="4" lg="3" class="py-0">
                    <v-fade-transition>
                        <v-card
                            v-show="pdfLoaded" elevation="12" class="pa-4"
                            :class="$vuetify.breakpoint.xl ? 'pdfViewerCardLarge' : 'pdfViewerCardMedium'"
                        >
                            <v-card-title class="py-0 pr-0">
                                How to sign?
                                <v-spacer/>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" icon @click="onCloseClicked">
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Stop signing</span>
                                </v-tooltip>
                            </v-card-title>
                            <v-card-text class="text-body-1">
                                <v-stepper v-model="signStepper" vertical flat class="ml-n6">
                                    <v-stepper-step
                                        :complete="signStepper > 1"
                                        step="1">
                                        Choose Location
                                    </v-stepper-step>
                                    <v-stepper-content step="1">
                                        Click on a location in the PDF to set a signature image.
                                    </v-stepper-content>

                                    <v-stepper-step
                                        :complete="signStepper > 2"
                                        step="2">
                                        Confirm Location
                                    </v-stepper-step>
                                    <v-stepper-content step="2">
                                        Confirm the location or reset the signature's location.
                                    </v-stepper-content>

                                    <v-stepper-step
                                        :complete="signStepper > 3"
                                        step="3">
                                        Sign with Authenticate app
                                    </v-stepper-step>
                                    <v-stepper-content step="3">
                                        Complete the process by signing the PDF with the Authenticate app.
                                    </v-stepper-content>
                                </v-stepper>

                            </v-card-text>
                            <v-card-actions v-if="signStepper === 2">
                                <v-row no-gutters>
                                    <v-spacer></v-spacer>
                                    <v-btn text @click="onChooseNewSignatureClicked" :disabled="submittingFile">
                                        Reset
                                    </v-btn>
                                    <v-btn text color="primary" @click="onConfirmSignatureClicked" class="ml-2"
                                           :disabled="submittingFile">
                                        <v-progress-circular
                                            v-show="submittingFile"
                                            size="20"
                                            class="mr-1"
                                            indeterminate
                                            color="primary"
                                        ></v-progress-circular>
                                        Confirm
                                    </v-btn>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </v-fade-transition>

                    <v-fade-transition>
                        <v-card
                            v-show="signedPDF"
                            elevation="12" class="pa-4"
                        >
                            <v-card-title class="py-0 pr-0">
                                Content Ready
                            </v-card-title>
                            <v-card-text class="text-body-1 pb-0">
                                You can now download your signed PDF.
                            </v-card-text>
                            <v-card-actions>
                                <v-btn color="primary" class="white--text" @click="onDownloadClicked">
                                    Download PDF
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-fade-transition>

                </v-col>

            </v-row>

        </v-container>


        <sign-pdf-dialog
            v-if="showSignPDFDialog"
            :creating-transaction="submittingFile"
            :signed-content="signedPDF"
            :pdf="pdf"
            :transaction-status="transactionStatus"
            @close="onCloseSigningDialogClicked"
            @restart="onConfirmSignatureClicked"
        ></sign-pdf-dialog>

    </v-container>

</template>

<script>
import PdfViewContainer from "@/components/PdfViewContainer";
import SignPdfDialog from "@/components/dialogs/SignPdfDialog";
import {SigningAPI} from "@/utils/api/signing";

const MAX_PDF_SIZE_IN_BYTES = 5 * 1000 * 1000;

export default {
    name: "HomePage",

    components: {
        SignPdfDialog,
        PdfViewContainer
    },

    data: () => ({
        coordinatesSet: false,
        inputFile: null,
        isSelectingFile: false,
        loaded: false,
        pdf: {
            content: null,
            name: "",
            signatureBox: {
                content: null,
                coordinates: {},
                page: 0,
            },
            transactionImage: null
        },
        pdfLoaded: false,
        signStepper: 1,
        signedPDF: null,
        showPDFSelector: true,
        showSignPDFDialog: false,
        submittingFile: false,
        transactionStatus: 0,
        transactionTimer: null,
    }),

    methods: {

        loadSignedDocument(identifier) {
            SigningAPI.downloadSignedPDF(identifier)
                .then((result) => {
                    this.signedPDF = result.content;
                })
                .catch((e) => {
                    console.error(e)
                })
        },

        onChooseNewSignatureClicked() {
            this.$refs.pdfViewContainer.clearSignature();
            this.signStepper = 1;
        },

        onCloseClicked() {
            this.pdfLoaded = false;
            this.signStepper = 1;
            this.pdf.transactionImage = null;
            this.pdf.name = "";
            this.pdf.content = null;
            this.pdf.signatureBox = {}
            this.inputFile = null;
            this.signedPDF = null;

            this.$refs.pdfViewContainer.clear();

            setTimeout(() => {
                this.showPDFSelector = true;
            }, 800);
        },

        onCloseSigningDialogClicked() {
            clearInterval(this.timer);
            this.showSignPDFDialog = false;

            if (this.transactionStatus < SigningAPI.STATUS_CODES.SIGNED) {
                this.signStepper = 2;
            }
        },

        onDownloadClicked() {
            const downloadElement = document.createElement("a");
            downloadElement.setAttribute(
                "href",
                "data:application/octet-stream;base64," + encodeURIComponent(this.signedPDF)
            );

            let strippedName = this.pdf.name.split(".pdf")[0];
            let signedPDFName = strippedName + "-signed.pdf"

            downloadElement.setAttribute("download", signedPDFName);
            downloadElement.style.display = "none";

            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement);
        },

        onFileChanged(event) {
            let file = event.target.files[0];

            if (!file.type.includes("pdf")) {
                console.error("Please submit a PDF")
                return;
            }

            if (file.size > MAX_PDF_SIZE_IN_BYTES) {
                console.error("Please submit a PDF with size smaller than 5 MB")
                return;
            }

            this.pdf.name = file.name;
            this.showPDF(file);
        },

        onSelectFileClicked() {
            this.isSelectingFile = true;

            // When the FilePicker is closed, set isSelecting to false
            window.addEventListener('focus', () => {
                this.isSelectingFile = false;
            }, {once: true})

            // Click on the hidden input field to select a file
            this.$refs.hiddenFileUploader.click();
        },

        onPdfRendered() {
            setTimeout(() => {
                this.pdfLoaded = true;
            }, 800);
        },

        onSignatureBoxSet(coordinates, page, signatureImage, transactionImage) {
            this.pdf.signatureBox = {
                content: signatureImage,
                coordinates: coordinates,
                page: page
            }

            this.pdf.transactionImage = transactionImage;
            this.signStepper = 2;
        },

        checkSigningRequestStatus(request) {
            this.showSignDocumentDialog = true;

            this.timer = setInterval(async () => {
                let result;

                try {
                    result = await SigningAPI.checkStatus(request.uuid);
                } catch (e) {
                    this.transactionStatus = SigningAPI.STATUS_CODES.ERROR;
                    clearInterval(this.timer);
                    return;
                }
                this.transactionStatus = result.status;

                switch (this.transactionStatus) {
                    case SigningAPI.STATUS_CODES.SIGNED:
                        this.loadSignedDocument(result.signed_content);
                        clearInterval(this.timer);
                        break;
                    case SigningAPI.STATUS_CODES.EXPIRED:
                    case SigningAPI.STATUS_CODES.ERROR:
                    case SigningAPI.STATUS_CODES.DENIED:
                        clearInterval(this.timer);
                        break;
                }

            }, 2000)
        },

        async onConfirmSignatureClicked() {
            console.log("Confirm signature")
            clearInterval(this.timer);
            this.submittingFile = true;

            this.transactionStatus = SigningAPI.STATUS_CODES.SUBMITTED;

            try {
                let result = await SigningAPI.submit(this.pdf);
                this.signStepper = 3;
                this.showSignPDFDialog = true;
                this.checkSigningRequestStatus(result);
            } catch (e) {
                console.error(e);
            }

            this.submittingFile = false;
        },

        async showPDF(file) {
            this.showPDFSelector = false;
            this.inputFile = file;

            const reader = new FileReader();

            reader.onloadend = () => {
                this.pdf.content = reader.result.replace('data:', '').replace(/^.+,/, '');
            }

            reader.readAsDataURL(file);
        },

    },

    async created() {
        this.loaded = true;
    },

}
</script>

<style scoped>

.pdfViewerCardLarge {
    top: -300px;
    margin-bottom: -270px;
}

.pdfViewerCardMedium {
    top: -330px;
    margin-bottom: -310px;
}

</style>