<template>

    <v-container class="py-0">

        <template v-if="true">

            <!-- QR code -->
            <v-row no-gutters id="qrcode"
                   class="colorTransition">

                <v-col cols="12" sm="12" class="mt-n4">

                    <v-responsive>
                        <v-img :src='"data:image/png;base64," + (waitingForRefresh ? mockImage : qrcode)'
                               :class="{'opacityStrong' : noQrCodeAvailable || waitingForRefresh, 'opacityHide': hideQrCode}"
                               transition="fade-transition"
                               aspect-ratio="1"
                               width="100%"
                               contain
                        >

                            <v-row v-if="noQrCodeAvailable"
                                   align="center" class="fill-height" justify="center">
                                <v-progress-circular color="primary" class="mr-2"
                                                     indeterminate></v-progress-circular>
                                <span>Loading QR code</span>
                            </v-row>

                            <v-row align="center" class="fill-height" justify="center" no-gutters>
                                    <div id="magicWhiteBox">
                                        <v-img
                                            :src="require('../../assets/authenticate.svg')"
                                            width="80%"
                                            height="80%"
                                            aspect-ratio="1"
                                            contain
                                        ></v-img>
                                    </div>
                            </v-row>
                        </v-img>
                    </v-responsive>

                </v-col>
            </v-row>

            <!-- Nonce -->
            <v-row no-gutters class="mt-0 mb-2" justify="center" v-if="nonce.length > 0">

                <v-col class="text-center">
                    <template v-if="!showNonce">
                        <span class="mr-2">{{ $t('no_scanner') }}</span>
                        <v-btn x-small
                               class="primary my-6"
                               @click="clickedOnShowNonce"
                        >{{ $t('show_nonce') }}
                        </v-btn>
                    </template>

                    <template v-else>
                        <span>{{ $t('enter_nonce') }}</span>
                        <p class="text-h6 mb-0">
                            <kbd
                                class="primary"
                                :class="{'opacityStrong' : noQrCodeAvailable}"
                            >{{ nonce.match(/.{1,3}/g).join(" ") }}</kbd>
                        </p>
                    </template>

                    <template v-if="showForOtherDevice">
                        <v-btn block color="green" dark @click="showForOtherDevice = false">
                            <v-icon class="mr-1">mdi-arrow-left</v-icon>
                            Go back
                        </v-btn>
                    </template>
                </v-col>
            </v-row>

        </template>

        <template v-else>

            <v-row no-gutters>
                <v-col cols="12">

                    <p class="title">{{ header }}</p>

                    <p>1. Click <kbd class="primary">Open App</kbd> to open the Authenticate app.</p>
                    <p>2. Optionally select the profile to login with.</p>
                    <p>Click on <kbd class="green">Show QR</kbd> to scan the QR code with another device.</p>
                </v-col>
            </v-row>

            <v-row no-gutters class="mb-4">
                <v-btn color="green" dark @click="showForOtherDevice = true">
                    <v-icon class="mr-1">mdi-qrcode-scan</v-icon>
                    Show QR
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn color="primary" @click="onOpenAuthenticateClicked">
                    <v-icon class="mr-1">mdi-cellphone</v-icon>
                    Open App
                </v-btn>
            </v-row>

        </template>

    </v-container>

</template>

<script>
import Utils from "@/utils/utils";

export default {
    name: "QrCodeView",

    props: {

        appApi: {
            type: String,
            required: false,
        },

        header: {
            type: String,
            required: true
        },

        hideQrCode: {
            type: Boolean,
            default: false
        },

        nonce: {
            type: String,
            required: true,
        },

        qrcode: {
            type: String,
            required: true
        },

        timeout: {
            type: Number,
            default: 60
        },

    },

    data: () => ({
        borderCalculated: 1,
        milliSecondsAlive: 0,
        millisecondsPerTick: 500,
        mockImage: "",
        showNonce: true,
        showForOtherDevice: false,
        timeoutPercentage: 0,
        waitingForRefresh: false,
    }),

    computed: {

        noQrCodeAvailable() {
            return this.qrcode.length === 0;
        },

    },

    watch: {

        qrcode(newVal) {
            if (newVal.length > 0) {
                this.onNewQRcodeReceived();
            }
        }

    },

    methods: {

        clickedOnShowNonce() {
            this.showNonce = true;
            this.$emit("nonceClicked");
        },

        onOpenAuthenticateClicked() {
            window.location.href = this.appApi;
        },

        onNewQRcodeReceived() {
            this.waitingForRefresh = false;
            this.milliSecondsAlive = 0;
            this.timeoutTimer = setInterval(this.updateTimeoutPercentage, this.millisecondsPerTick);
        },

        onTimeoutReached() {
            clearInterval(this.timeoutTimer);
            this.waitingForRefresh = true;
            this.milliSecondsAlive = 0;
            this.$emit("onTimeout");
        },

        updateTimeoutPercentage() {
            this.milliSecondsAlive += this.millisecondsPerTick;

            if (this.milliSecondsAlive > this.timeout * 1000) {
                this.onTimeoutReached();
            }

            let percentageToTimeout = (this.milliSecondsAlive / (this.timeout * 1000)) * 100;

            this.timeoutPercentage = Math.min(percentageToTimeout, 100);
        },

    },

    beforeDestroy() {
        if (this.timeoutTimer) {
            clearInterval(this.timeoutTimer);
        }
    },

    created() {
        this.mockImage = Utils.getMockImage();

        setInterval(() => {
            if (this.borderCalculated === 50) {
                this.borderCalculated = 0;
            } else {
                this.borderCalculated += 1
            }
        }, 200)

    },


}
</script>

<style>

.colorTransition {
    transition: 0.4s linear background-color;
}

.opacityStrong {
    opacity: 0.6;
}

.opacityHide {
    opacity: 0.1;
}

#magicWhiteBox {
    background-color: white;
    border-radius: 50%;
    border-style: solid;
    border-color: #2871b4;
    border-width: 5px;
    width: 28%;
    height: 28%;
    align-items: center;
    display: flex;
}



</style>

<i18n>
{
    "en": {
        "no_scanner": "Can't scan QR code?",
        "show_nonce": "Show nonce",
        "enter_nonce": "Toggle to manual to enter this code"
    }
}
</i18n>