const Utils = {

    generateRandom(length) {
        let randomString = "";

        let regex = new RegExp("[^A-Za-z0-9]", "g");

        while (randomString.length < length) {
            let random = new Uint8Array(length);
            window.crypto.getRandomValues(random);

            randomString += this.ab2str(random).replace(regex, "");
        }

        return randomString.slice(0, length);
    },

    async calcSha256(input) {
        let hash = await crypto.subtle.digest("SHA-256", this.str2ab(input));
        return this.ab2str(hash);
    },

    dateInFuture(secondsFromNow) {
        let now = new Date();
        let thing = new Date(now.getTime() + secondsFromNow * 1000);
        console.log(thing);
        return thing;
    },

    ab2str(ab) {
        return String.fromCharCode.apply(null, new Uint8Array(ab));
    },

    str2ab(str) {
        const buf = new ArrayBuffer(str.length);
        const bufView = new Uint8Array(buf);

        for (let i = 0, strLen = str.length; i < strLen; i++) {
            bufView[i] = str.charCodeAt(i);
        }

        return buf;
    },

    b64ToBlob(b64Data, sliceSize = 512) {
        let contentType = 'image/png'

        let byteCharacters = atob(b64Data);
        let byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize);
            let byteNumbers = new Array(slice.length);

            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            let byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, {type: contentType});
    },

    getMockImage() {
        return "iVBORw0KGgoAAAANSUhEUgAAAcIAAAHCAQAAAABUY/ToAAADlklEQVR4nO2cTa6bShCFT6WRPAQpC/BSYAdZUpQlZQf0UrKAJ8HwS" +
            "qDzBtV/4KsMEt93bb9TAwsDnxqko+r66caIP7P45Q9BQKRIkSJFihQp8vFIS9YBWM0QrQOimfnVaU0X3KZPflqRj0WCJImRJLkEkgtAc" +
            "is3BAII+Wq5eX6u9xT58eSa/IvZEIhoHcglEOg3AP2GdNW6+40p8kXJcQFsQiDidQPGXx2AtTvf9iBPK/IByLM4GAcY0P9jHBeAcQhg" +
            "/LaAWO81psjXIrOGegJVJePPCxEngMBuGJd0vi1IPtd7ivw4MmkoegYWYOPydTMA6cj/rl83G3/udp8xRb4W6Rqq/oXAm/lPvKYjpPnt" +
            "2BZ5rvcU+R+Qu9m0miEOgTbls340LoGcAXjh6G5jinwJMtWHAIBzzuNdL36u1IfmnnQ1UfUhka0lRSDQQ+eR9KMspMAkLhwKktKQyGxJ" +
            "Qz0J9PQ6dbJ+O0uKM+SHRJ4t+yHg0M0ovY6e9NbHuOSbpSGRB6uiSRFP6ZzN7odSjAQoHhL5viUN1XZrzsFOIXaNrhUPiTxanZ1S7MPk" +
            "fZJo+uPUVghpSGS2JgCqzqhMY03mX7yU/JDIozV5WQmK2hQegE9o9Kub/JDId8lxAfjDLvR1jFg78MeQGmSe70e7eDxk02c/rciHIg+" +
            "5vc9bac1i8j5LLlGXaqP8kMijNbl9CoVSQaitCi0AakomDYk8WNZEDoVqZFQr1m2RKNWupSGRxXJ9CJ6X5fVmgc1VAE0FUhoSebTkhh" +
            "ak3ljdyOGz2pLnsjqrSUMi3yP7DZzXDhj5Zhj5Zr6rLA6BZtcsrnglT+RnPK3IRyJ9SZm59+k3EAg0ACDWwQgAjN/2DnEIMPRl0eNzva" +
            "fIjyObjN7jIaANik7JWfqruUxka23PlbXJepRPLlE3C0CkIZFncjczr1NfaN8XwKb14j1Xs6FsukdeY/2s7yny7mS7rMxnNS8SlaVDCH" +
            "mlbK1iyw+JvCGb7354KOQ+J5D0NG1D3TBt013GFPkq5M13P2aUnxIZzUCuHqlfJvLGznVqb2kcGq91r3ReoSYNifwduXZpW1C8bvkDMj" +
            "nfb9zSgzytyM8nb7774XvrCQBr5zvvGa8bCGydjfPeYZz/bkyRr0XefPcjTqFsv98N47x3Ni57R6xD+bbD340p8rXIpjOPuqe1hD11IR" +
            "pKH1bxkMiDmb5xLlKkSJEiRYr8n5P/Ak9fY6lrFNDHAAAAAElFTkSuQmCC"
    }

}

export default Utils;