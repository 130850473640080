<template>
    <svg version="1.1" id="pdfSignatureBox" xmlns="http://www.w3.org/2000/svg"
         height="50" width="260" viewBox="0 0 130 25" xml:space="preserve"
    >
        <text x="0" y="10" id="signatureName" style="font-style: italic; font-family: 'Times New Roman', serif; font-size: 10px">
            {{ name }}
        </text>
        <line x1="0" y1="14" x2="130" y2="14" style="stroke: #2871b4; stroke-width: 1"/>
        <text x="0" y="21" id="signatureDate" style="font-family: 'Times New Roman', sans-serif; font-size: 6px; fill: #2871b4">
            {{ locationAndDate }}
        </text>
    </svg>
</template>

<script>

export default {
    name: "SignatureBox",

    computed: {

        locationAndDate() {
            let now = new Date();
            return this.name + ", " + now.toUTCString();
        },

        name() {
            return this.$store.state.session.activeUser.common_name;
        }
    },

}
</script>

<style lang="css">

</style>