import Vue from 'vue';
import Vuex from 'vuex';
import session from "./modules/session";
import mutations from "@/store/mutations";

Vue.use(Vuex)

const store = new Vuex.Store({

    modules: {
        session
    },

    state: {
        serviceUnavailable: false,
    },

    mutations,

})

export default store;