<template>

    <v-app>

        <template>

            <v-app-bar
                app
                outlined
                flat
            >

                <v-container fluid class="fill-height py-0 px-0" style="max-width: 1920px;">
                    <v-img
                        :src="require('./assets/ubiqu.svg')"
                        max-height="40"
                        max-width="40"
                        contain
                        class="mr-4 ml-0"
                    ></v-img>

                    <v-toolbar-title class="font-weight-bold">
                        Signing Service
                    </v-toolbar-title>

                    <v-spacer/>

                    <v-menu
                        v-if="activeUser"
                        open-on-click
                        offset-y
                        bottom
                        transition="slide-y-transition"
                    >
                        <template v-slot:activator="{ on }">
                            <div v-on="on" class="align-center d-flex clickable">
                                <v-avatar color="primary" size="36" class="ml-4">

                                <span class="white--text">
                                    {{ activeUser.common_name.substring(0, 1).toUpperCase() }}
                                </span>

                                </v-avatar>
                            </div>
                        </template>

                        <v-card width="350">

                            <!-- Active user -->
                            <v-card-title class="justify-center pb-0">
                                <v-avatar class="justify-center white--text text-h3" size="75"
                                          color="primary">
                                    {{ activeUser.common_name.substring(0, 1).toUpperCase() }}
                                </v-avatar>
                            </v-card-title>
                            <v-card-title class="justify-center pb-0">
                                {{ activeUser.account_id }}
                            </v-card-title>
                            <v-card-text class="text-center">
                                <p>KPN Mobiele Certificaten</p>
                                <v-btn outlined small @click="clickedOnLogout">
                                    <v-icon small>mdi-logout</v-icon>
                                    Logout
                                </v-btn>
                            </v-card-text>

                            <v-list class="py-0">

                                <!-- Remaining users -->
                                <template v-for="(user, index) in otherUsers">

                                    <v-divider :key="index"></v-divider>

                                    <v-list-item :key="user.uuid" two-line target="_blank" :href="clickedOnUserURL(user)">
                                        <v-list-item-avatar color="primary" size="36"
                                                            class="white--text justify-center">
                                            {{ user.common_name.substring(0, 1).toUpperCase() }}
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ user.account_id }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                KPN Mobiele Certificaten
                                            </v-list-item-subtitle>
                                        </v-list-item-content>

                                        <v-tooltip bottom v-if="user.logged_in">
                                            <template v-slot:activator="{ on }">
                                                <v-list-item-icon v-on="on" @click.stop.prevent="clickedOnLogoutUser(user)">
                                                    <v-icon>mdi-close</v-icon>
                                                </v-list-item-icon>
                                            </template>
                                            <span>Logout user</span>
                                        </v-tooltip>

                                        <small v-else class="justify-end font-italic">
                                            Signed out
                                        </small>

                                    </v-list-item>
                                </template>

                                <v-divider></v-divider>

                                <!-- Add account -->
                                <v-list-item target="_blank" :href="addUserUrl" class="pl-3">
                                    <v-list-item-avatar>
                                        <v-icon>mdi-account</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            Use another account
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                            </v-list>

                        </v-card>

                    </v-menu>
                </v-container>

            </v-app-bar>

        </template>

        <v-main class="grey lighten-3">
            <router-view style="height: 100%"></router-view>
        </v-main>

        <service-unavailable-dialog
            v-if="isServiceUnavailable"
        ></service-unavailable-dialog>

    </v-app>

</template>

<script>
import SessionManager from "@/utils/session";
import SessionAPI from "@/utils/api/session";
import ServiceUnavailableDialog from "@/components/dialogs/ServiceUnavailableDialog";

export default {
    name: "App",

    components: {
        ServiceUnavailableDialog
    },

    data: () => ({
        addUserUrl: "",
        image: "",
        isLoggedIn: false,
        isUbiqu: true,
        navBarItems: [
            {
                title: "My Profile", icon: "mdi-account", route: "home"
            }
        ],
    }),

    watch: {

        $route(to) {
            this.isLoggedIn = to.name === "home";
        },

    },

    computed: {

        activeUser() {
            return this.$store.state.session.activeUser;
        },

        otherUsers() {
            let allUsers = this.$store.state.session.users;

            return allUsers.filter((user) => user.uuid !== this.activeUser.uuid);
        },

        isServiceUnavailable() {
            return this.$store.state.serviceUnavailable;
        },

    },

    methods: {
        clickedOnUserURL(user) {
            if (user.logged_in) {
                return "/user/" + user.uuid + "/home";
            } else {
                return config.BASE_URL + "login?uid=" + user.uuid
            }
        },

        clickedOnLogoutUser(user) {
            SessionAPI.logout(user.uuid);
            user.logged_in = false;
        },

        async clickedOnLogout() {
            await SessionManager.logout();
            await this.$router.push({name: "login"});
        },
    },

    created() {
        this.addUserUrl = config.BASE_URL + "login?new=1";

        SessionAPI.init(() => {
            this.$router.push({name: "login"});
        })
    },

}
</script>

<style>

.v-navigation-drawer__border {
    display: none;
}

.clickable {
    cursor: pointer;
}

</style>