<template>

    <v-card elevation="6">

        <v-row no-gutters>

            <v-col cols="12">

                <v-card-text class="text-subtitle-1 ma-2">

                    <p class="title">Welcome back</p>

                    <p class="subtitle-1">Choose a user to continue</p>

                    <v-list class="py-0 pr-4">

                        <!-- Remaining users -->
                        <template v-for="(user, index) in users">
                            <v-list-item :key="user.uuid" two-line @click.stop.prevent="clickedOnLoginUser(user)" :disabled="loading">
                                <v-list-item-avatar color="primary" size="36"
                                                    class="white--text justify-center">
                                    {{ user.common_name.substring(0, 1).toUpperCase() }}
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ user.account_id }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle v-if="user.logged_in">
                                        Logged in
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle v-else>
                                        Currently not logged in
                                    </v-list-item-subtitle>
                                </v-list-item-content>

                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-list-item-icon v-on="on" @click.stop.prevent="clickedOnRemoveUser(user)" class="my-auto">
                                            <v-icon>mdi-close</v-icon>
                                        </v-list-item-icon>
                                    </template>
                                    <span>Forget user</span>
                                </v-tooltip>

                            </v-list-item>

                            <v-progress-linear
                                v-if="idOfUserClicked === user.uuid"
                                indeterminate color="primary"
                                :key="'loader-' + index">
                            </v-progress-linear>

                            <v-divider :key="'divider-' + index"></v-divider>
                        </template>

                        <!-- Add account -->
                        <v-list-item @click.stop.prevent="clickedOnAddNewUser" class="pl-3">
                            <v-list-item-avatar>
                                <v-icon>mdi-account</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Use another account
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider></v-divider>

                    </v-list>
                </v-card-text>

            </v-col>

        </v-row>
    </v-card>

</template>

<script>
export default {
    name: "UserListCard",

    props: {

        loading: {
            type: Boolean,
            default: false
        }

    },

    data: () => ({
        idOfUserClicked: "",
    }),

    computed: {

        users() {
           return this.$store.state.session.users;
        }

    },

    watch: {

        loading(value) {
            if (!value) {
                this.idOfUserClicked = ""
            }
        }

    },

    methods: {

        clickedOnAddNewUser() {
            this.$emit("add");
        },

        clickedOnLoginUser(user) {
            this.idOfUserClicked = user.uuid;
            this.$emit("login", user);
        },

        clickedOnRemoveUser(user) {
            this.$emit("remove", user);
        }

    },
}
</script>

<style scoped>

</style>