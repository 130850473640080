<template>

    <div id="pdf-wrapper"
         class="my-4 mx-2 py-4"
         style="background: #ddd; max-height: 900px; overflow: auto"
    >

        <signature-box class="d-none" id="signatureBox"
        ></signature-box>

    </div>

</template>

<script>
import {PDFUtility} from "@/utils/pdf";
import SignatureBox from "@/components/SignatureBox";

const IMAGE_WIDTH = 260
const IMAGE_HEIGHT = 50

export default {
    name: "PdfViewContainer",
    components: {SignatureBox},
    props: {

        file: {
            type: File,
            required: true
        },

        readOnly: {
            type: Boolean,
            default: false
        }

    },

    data: () => ({
        currentImageDrawn: null,
        imageData: null,
        imageToDraw: null,
        pdf: null,
        rendered: false,
    }),

    watch: {
    },

    methods: {

        /**
         * Removes all rendered pages from the DOM
         */
        clear() {
            // Clears all rendered pages
            let canvasContainer = document.getElementById("pdf-wrapper");
            while (canvasContainer.firstChild) {
                canvasContainer.removeChild(canvasContainer.firstChild);
            }
        },

        async clearSignature() {
            await this.clearCurrentImageDrawn();
            this.currentImageDrawn = null;
        },

        /**
         * Converts the binary file to a PDF object using pdfjs
         * @returns {Promise<void>}
         */
        async convertFileToPDF() {
            let binaryFile = await this.file.arrayBuffer();
            this.pdf = await PDFUtility.load(binaryFile);
        },

        /**
         * Clears the current image drawn in the pdf
         * @returns {Promise<unknown>}
         */
        async clearCurrentImageDrawn() {
            return new Promise((resolve, reject) => {
                if (this.currentImageDrawn == null) {
                    resolve();
                }

                this.currentImageDrawn.page.render(this.currentImageDrawn.renderContext).promise
                .then(() => {
                    resolve();
                })
                .catch((e) => {
                    reject(e)
                })
            })

        },

        /**
         * Renders a single pdf page
         * @param page
         * @param pageNumber
         * @returns {Promise<void>}
         */
        async renderPage(page, pageNumber) {
            let scale = 1.0
            let viewport = page.getViewport({scale: scale});

            let wrapper = document.createElement("div");
            wrapper.className = "canvas-wrapper";

            let canvas = document.createElement("canvas");
            let context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            let renderContext = {
                canvasContext: context,
                viewport: viewport
            }

            function areCoordinatesAllowed(coordinates) {
                let centerOffsetX = IMAGE_WIDTH/2;
                let centerOffsetY = IMAGE_HEIGHT/2;

                // Check if image doesn't exceed width of canvas
                if (coordinates.x + centerOffsetX  < 0 || coordinates.x > canvas.width) {
                    return false;
                }

                // Check if image doesn't exceed height of canvas
                if (coordinates.y + centerOffsetY < 0 || coordinates.y > canvas.height) {
                    return false;
                }

                return true;
            }

            function applyFilterToCoordinates(coordinates) {
                // Check if image doesn't exceed width of canvas
                if (coordinates.x + IMAGE_WIDTH > canvas.width) {
                    coordinates.x = canvas.width - IMAGE_WIDTH;
                } else if (coordinates.x < 0) {
                    coordinates.x = 0;
                }

                // Check if image doesn't exceed height of canvas
                if (coordinates.y + IMAGE_HEIGHT > canvas.height) {
                    coordinates.y = canvas.height - IMAGE_HEIGHT;
                } else if (coordinates.y < 0) {
                    coordinates.y = 0;
                }
            }

            function calibrateCoordinates(clientX, clientY) {
                const rect = canvas.getBoundingClientRect();
                const x = clientX - rect.left;
                const y = clientY - rect.top;

                let centerOffsetX = IMAGE_WIDTH/2;
                let centerOffsetY = IMAGE_HEIGHT/2;

                const xOffset = Math.floor(x - centerOffsetX);
                const yOffset = Math.floor(y - centerOffsetY);

                return {x: xOffset, y: yOffset}
            }

            canvas.onmousedown = async (e) => {
                if (this.readOnly) return;

                let coordinates = calibrateCoordinates(e.clientX, e.clientY);

                if (!areCoordinatesAllowed(coordinates)) {
                    return;
                }

                applyFilterToCoordinates(coordinates);

                await this.clearCurrentImageDrawn();
                context.drawImage(this.imageToDraw, coordinates.x, coordinates.y, IMAGE_WIDTH, IMAGE_HEIGHT);

                this.currentImageDrawn = {
                    coordinates: coordinates,
                    page: page,
                    pageNumber: pageNumber,
                    renderContext: renderContext
                }

                let pngCurrentPage = canvas.toDataURL();
                let pngCurrentPageRaw = pngCurrentPage.replace('data:', '').replace(/^.+,/, '');

                this.$emit("set", coordinates, pageNumber, this.imageData, pngCurrentPageRaw);
            }

            canvas.onmousemove = (e) => {
                if (this.readOnly) {
                    canvas.style.cursor = "default";
                    return;
                }

                let coordinates = calibrateCoordinates(e.clientX, e.clientY);

                if (!areCoordinatesAllowed(coordinates)) {
                    canvas.style.cursor = "default";
                } else {
                    canvas.style.cursor = "pointer";
                }
            }

            wrapper.appendChild(canvas);

            let canvasContainer = document.getElementById("pdf-wrapper");
            canvasContainer.appendChild(wrapper);

            await page.render(renderContext);
        },

        /**
         * Renders the whole PDF
         * @returns {Promise<void>}
         */
        async renderPDF() {
            for (let num = 1; num <= this.pdf.numPages; num++) {
                let page = await this.pdf.getPage(num);
                await this.renderPage(page, num);
            }
        },

        /**
         * Setup the signature that will be drawn
         */
        async setupDrawableSignature() {
            let image = new Image();

            let signatureBox = document.getElementById("signatureBox");
            let xml = new XMLSerializer().serializeToString(signatureBox);

            let svg64 = btoa(xml);
            let b64Start = 'data:image/svg+xml;base64,';

            image.src = b64Start + svg64;

            image.onload = () => {
                let canvas = document.createElement("canvas");
                canvas.width = image.width;
                canvas.height = image.height;

                let context = canvas.getContext("2d");
                context.drawImage(image, 0, 0);

                const dataUrl = canvas.toDataURL("image/png");
                this.imageData = dataUrl.replace(/^data:image\/(png|jpg);base64,/, "");
            }

            this.imageToDraw = image;
        },

        /**
         * Setup the image that will be drawn
         */
        async setupDrawableImage() {
            let image = new Image();

            image.src = require("../assets/ubiqu.svg")

            // Convert image data to base64 such that we can upload it to server
            image.onload = () => {
                let canvas = document.createElement("canvas");
                canvas.width = image.width;
                canvas.height = image.height;

                let context = canvas.getContext("2d");
                context.drawImage(image, 0, 0);

                const dataUrl = canvas.toDataURL("image/png");
                this.imageData = dataUrl.replace(/^data:image\/(png|jpg);base64,/, "");
            }

            this.imageToDraw = image;
        },

    },

    async created() {
        await this.convertFileToPDF();
        await this.renderPDF();

        await this.setupDrawableSignature();

        this.$emit("rendered")
    }

}
</script>

<style>

canvas {
    margin: 0 auto;
    display: block;
}

.canvas-wrapper {
    margin-bottom: 8px;
}

.canvas-wrapper:first-child {
    margin-top: 8px;
}

</style>