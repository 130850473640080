<template>

    <v-container class="mt-12">

        <!-- Login header -->
        <v-row>
            <v-col offset="1" cols="10">
                <p class="text-h4 font-weight-bold primary--text">
                    Login
                </p>
            </v-col>
        </v-row>

        <!-- Login Card -->
        <v-fade-transition>
            <v-row v-show="loaded" class="mt-0">
                <v-col offset="1" cols="10">

                    <!-- QR code card -->
                    <v-card v-if="loginView === 0" elevation="6">

                        <v-toolbar v-if="canGoBackToUserList" flat class="py-0">
                            <v-spacer/>
                            <v-tooltip bottom>
                                <template v-slot:activator="{on}">
                                    <v-btn v-on="on" icon large @click="onBackToUserListClicked">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Back to user list</span>
                            </v-tooltip>

                        </v-toolbar>

                        <v-row no-gutters class="px-8" :class="canGoBackToUserList ? 'pb-12' : 'py-12'">

                            <v-col cols="5">
                                <qr-code-view
                                    :hide-qr-code="showLoginDialog"
                                    header="Hallo pietje"
                                    :nonce="loginData.nonce"
                                    :qrcode="loginData.qrcode"
                                    @onTimeout="updateIdentificationCode"
                                ></qr-code-view>
                            </v-col>

                            <v-col cols="7">

                                <v-card-text class="text-h6 font-weight-regular pt-0">

                                    <p class="text-h5 font-weight-medium">
                                        How to login
                                    </p>

                                    <p>1. {{ $t('step1') }} </p>
                                    <p>2. {{ $t('step2') }} </p>
                                    <p>3. {{ $t('step3') }} </p>

                                </v-card-text>

                            </v-col>

                        </v-row>
                    </v-card>

                    <user-list-card
                        v-if="loginView === 1"
                        :loading="loadingUserLoginSession"
                        @add="onAddNewUserClicked"
                        @login="onLoginWithUser"
                        @remove="onRemoveUserClicked"
                    ></user-list-card>

                </v-col>

            </v-row>

        </v-fade-transition>

        <login-dialog
            v-if="showLoginDialog"
            :isAppAlreadyOpen="isUserInApp"
            :isRejected="loginAttemptRejected"
            :user="user"
            @close="onCloseLoginDialogClicked"
            @restart="onRestartLoginClicked"
        ></login-dialog>

    </v-container>

</template>

<script>
import QrCodeView from "@/views/login/QRCodeView";
import LoginDialog from "@/components/dialogs/LoginDialog";
import UserListCard from "@/views/login/UserListCard";
import SessionAPI from "@/utils/api/session";
import SessionManager from "@/utils/session";

const LOGIN_VIEW = {
    IDENTIFY_NEW_USER: 0,
    KNOWN_USERS_LIST: 1,
}

export default {
    name: "LoginPage",

    components: {
        UserListCard,
        LoginDialog,
        QrCodeView
    },

    data: () => ({
        isUserInApp: false,
        loaded: false,
        loadingUserLoginSession: false,
        loadingNewLoginSession: false,
        loginData: {
            nonce: "",
            qrcode: "",
            uuid: "",
        },
        loginAttemptRejected: false,
        loginStatus: 0,
        loginStatusTimer: null,
        loginView: -1,
        newUserLoginRequested: false,
        showLoginDialog: false,
        user: null,
    }),

    computed: {

        areUsersAvailable() {
            return this.$store.state.session.users.length > 0;
        },

        canGoBackToUserList() {
            return this.areUsersAvailable && !this.newUserLoginRequested;
        }

    },

    watch: {

      loginStatus(newVal, oldVal) {
          // User receives transaction
          if (newVal === SessionAPI.LOGIN_STATUS.LOGGING_IN) {
              this.loginAttemptRejected = false;
              this.showLoginDialog = true;
          }

          // Transaction not completed successfully
          if (oldVal === SessionAPI.LOGIN_STATUS.LOGGING_IN && newVal < SessionAPI.LOGIN_STATUS.LOGGING_IN) {
              clearTimeout(this.loginStatusTimer);
              this.loginAttemptRejected = true;
          }

          console.log(`State change: ${oldVal} -> ${newVal}`)
      },

    },

    methods: {

        async createNewLoginSession() {
            this.updateIdentificationCode().then(() => {
                this.loadingNewLoginSession = false;
                this.checkUntilLoginSessionFinished();
            })
        },

        async updateIdentificationCode() {
            this.loadingNewLoginSession = true;

            let response = await SessionAPI.newLogin();

            this.loginData.nonce = response.nonce;
            this.loginData.qrcode = response.login_session_qr;
            this.loginData.uuid = response.uuid;
        },

        async checkUntilLoginSessionFinished() {
            let verifyStatusOfLoginProcess = async () => {
                let response = await SessionAPI.getLoginStatus(this.loginData.uuid);

                this.loginStatus = response.status;

                switch (this.loginStatus) {
                    case SessionAPI.LOGIN_STATUS.CREATED:
                    case SessionAPI.LOGIN_STATUS.IDENTIFIED:
                    case SessionAPI.LOGIN_STATUS.LOGGING_IN:
                        this.user = response.user;
                        break;
                    case SessionAPI.LOGIN_STATUS.LOGGED_IN:
                        this.completeLoginProcess();
                        return;
                }

                this.loginStatusTimer = setTimeout(verifyStatusOfLoginProcess, 2000);
            }

            await verifyStatusOfLoginProcess();
        },

        completeLoginProcess() {
            clearTimeout(this.loginStatusTimer);
            SessionManager.userLoggedIn(this.user);
            this.$router.push({name: 'home', params: {uid: this.user.uuid}});
        },

        onAddNewUserClicked() {
            this.loginView = LOGIN_VIEW.IDENTIFY_NEW_USER;
            this.createNewLoginSession();
        },

        onBackToUserListClicked() {
            this.loginView = LOGIN_VIEW.KNOWN_USERS_LIST;
            clearTimeout(this.loginStatusTimer);
        },

        onCloseLoginDialogClicked() {
            this.showLoginDialog = false;
        },

        onRestartLoginClicked() {
            this.loginAttemptRejected = false;

            SessionAPI.relogin(this.user.uuid).then((response) => {
                this.loginData.uuid = response.uuid;
                this.checkUntilLoginSessionFinished();
            }).catch((error) => {
                this.loginAttemptRejected = true;
                console.log("Could not login with user")
                console.error(error);
            })
        },

        onLoginWithUser(user) {
            this.loadingUserLoginSession = true;

            if (user.logged_in) {
                SessionManager.userLoggedIn(user);
                this.$router.push({name: 'home', params: {uid: user.uuid}});
                return
            }

            SessionAPI.relogin(user.uuid).then((response) => {
                this.loadingUserLoginSession = false;
                this.loginData.uuid = response.uuid;
                this.checkUntilLoginSessionFinished();
            }).catch((error) => {
                this.loadingUserLoginSession = false;
                console.log("Could not login with user")
                console.error(error);
            })
        },

        async onRemoveUserClicked(user) {
            await SessionAPI.remove(user.uuid);
            await this.$store.dispatch("session/loadUsers");

            if (!this.areUsersAvailable) {
                this.loginView = LOGIN_VIEW.IDENTIFY_NEW_USER;
                this.createNewLoginSession().then();
            }
        },

    },

    beforeDestroy() {
        if (this.loginStatusTimer) {
            clearTimeout(this.loginStatusTimer);
        }
    },

    created() {
        if (this.$route.query.uid) {
            this.$store.dispatch("session/loadUsers").then(() => {
                let user = {logged_in: false, uuid: this.$route.query.uid};
                this.onLoginWithUser(user);
                this.loginView = LOGIN_VIEW.KNOWN_USERS_LIST;
                this.loaded = true;
            });
            return;
        }

        this.newUserLoginRequested = this.$route.query.new;

        if (this.newUserLoginRequested) {
            this.$store.dispatch("session/loadUsers").then();
        }

        if (this.areUsersAvailable && !this.newUserLoginRequested) {
            this.loginView = LOGIN_VIEW.KNOWN_USERS_LIST;
        } else {
            this.loginView = LOGIN_VIEW.IDENTIFY_NEW_USER;
            this.createNewLoginSession();
        }

        this.loaded = true;
    },

    mounted() {
    },

}
</script>

<style scoped>

</style>

<i18n>
{
    "en": {
        "step1": "Open the Authenticate app.",
        "step2": "Use the QR code scanner to scan the QR code.",
        "step3": "Optionally select the profile you would like to login with."
    }
}
</i18n>