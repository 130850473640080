import Vue from 'vue';
import VueRouter from "vue-router";
import LoginPage from "@/views/LoginPage";
import HomePage from "@/views/HomePage";
import BindingManager from "@/utils/api/binding";
import SessionManager from "@/utils/session";
import store from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
    routes: [
        {
            path: '/login',
            name: 'login',
            component: LoginPage,
        },
        {
            path: '/user/:uid/home',
            name: 'home',
            component: HomePage,
        },
        {
            path: "/authorize/",
        },
        {
            path: '*', redirect: {name: 'home'}
        },
    ],
    mode: 'history',
});

export default router;

router.beforeEach(async (to, from, next) => {

    // Authorization route
    if (to.path === "/authorize/") {
        let otp = to.query["otp"];

        BindingManager.acknowledge(otp)
            .then(() => {
                next("/", () => {});
            })
            .catch((error) => {
                console.log(error)
                store.commit("serviceUnavailable", true)
            })

        return;
    }

    // Catch the login query paths
    if (to.name === "login" && (to.query.new || to.query.uid)) {
        next();
        return
    }

    let isLoggedIn;

    try {
        isLoggedIn = await SessionManager.isLoggedIn(to.params.uid);
    } catch (e) {
        switch (e.message) {
            case SessionManager.ERRORS.X_CSRF_TOKEN_INVALID:
                await BindingManager.start();
                break;
            default:
                console.error(e);
        }
        return;
    }


    // Authentication header guard
    if (to.name !== "login" && !isLoggedIn) {
        next({ name: "login", params: {} })
        return
    }

    if (to.name === "login" && isLoggedIn) {
        let activeUserID = store.state.session.activeUser.uuid;
        next({ name: "home", params: {uid: activeUserID} })
    } else {
        next();
    }

});