import SessionAPI from "@/utils/api/session";

const state = () => ({
    users: [],
    activeUser: null,
})

/** Session module mutations **/
const mutations = {

    setActiveUser(state, user) {
        state.activeUser = user;
    },

    setUsers(state, users) {
        state.users = users;
    }

}

const actions = {

    async loadUsers({ commit }) {
        let userList = await SessionAPI.getUsers();
        commit("setUsers", userList)
        return userList;
    }

}

const getters = {

}



export default {
    namespaced: true,
    mutations,
    actions,
    getters,
    state,
}