<template>

    <v-dialog
        v-model="modal"
        persistent
        max-width="500"
        overlay-opacity="0.9"
    >

        <v-card>

            <v-toolbar color="primary" dark flat>

                <v-toolbar-title class="font-weight-medium">
                    <v-icon>mdi-cellphone-sound</v-icon>
                    Login with Authenticate
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="closeDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <span>Cancel Login</span>
                </v-tooltip>

            </v-toolbar>

            <v-container class="px-4">

                <p class="title text--primary">Hi {{ user.common_name }}</p>

                <template v-if="!isRejected">

                    <template v-if="isAppAlreadyOpen">
                        <p class="">
                            You just received a notification in your app. Confirm this with your PIN in order login.
                        </p>

                        <v-alert type="info" dense text color="primary">
                            Didn't receive a notification? Reopen the app and refresh your screen.
                        </v-alert>
                    </template>

                    <template v-else>
                        <p class="">
                            Open the notification you received on your phone.
                            Click on the message you and confirm this with your PIN in order login.
                        </p>


                        <v-alert type="info" dense text color="primary">
                            Didn't receive a notification? Open the app and refresh your screen.
                        </v-alert>
                    </template>

                </template>

                <v-list two-line class="pt-0 mt-n4" subheader>
                    <v-subheader class="font-weight-medium px-0">Login Details</v-subheader>

                    <v-list-item class="px-0">
                        <v-list-item-content>
                            <v-list-item-title>{{ expiresAt.toLocaleString() }}</v-list-item-title>
                            <v-list-item-subtitle>Expires at</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                </v-list>

                <v-progress-linear
                    v-if="!isRejected"
                    indeterminate
                    color="primary"
                ></v-progress-linear>

                <v-alert v-if="isRejected" type="error" dense text>
                    Login attempt rejected.
                    <template v-slot:append>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" color="red" icon small @click="restartTransaction">
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>Try again</span>
                        </v-tooltip>
                    </template>
                </v-alert>

            </v-container>

        </v-card>



    </v-dialog>

</template>

<script>
import Utils from "@/utils/utils";

export default {
    name: "LoginDialog",

    props: {

        isAppAlreadyOpen: {
            type: Boolean,
            required: true
        },

        isRejected: {
            type: Boolean,
            required: true
        },

        transactionDuration: {
            type: Number,
            default: 300
        },

        user: {
            type: Object,
            required: true
        },

    },

    data: () => ({
        expiresAt: null,
        modal: true,
    }),

    watch: {

      isRejected(newValue) {

          // WHen transaction is loaded again (rejected set to false) reset the expiration date
          if (!newValue) {
              this.expiresAt = Utils.dateInFuture(this.transactionDuration);
          }

      }

    },

    methods: {

        closeDialog() {
            this.$emit("close");
        },

        restartTransaction() {
            this.$emit("restart");
        },

    },

    created() {
        this.expiresAt = Utils.dateInFuture(this.transactionDuration);
    }

}
</script>

<style scoped>

</style>