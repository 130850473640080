import axios from "axios";
import Utils from "@/utils/utils";
import SessionManager from "@/utils/session";
import {StorageManager} from "@/utils/storage";
import * as assert from "assert";

const CONFIG = {
    CODE_CHALLENGE_LENGTH: 128,
    AUTHORIZATION_ATTRIBUTE: "api_authorization",
}

const BindingManager = {

    /**
     * Start binding flow with demo backend in order to receive X-CSRF Token
     */
    async start() {
        let codeVerifier = Utils.generateRandom(CONFIG.CODE_CHALLENGE_LENGTH);
        let hashedVerifier = await Utils.calcSha256(codeVerifier);

        let codeChallenge = btoa(hashedVerifier);
        codeChallenge = codeChallenge.replace(/\+/g, "-");
        codeChallenge = codeChallenge.replace(/\//g, "_");

        let authorizationObject = {
            codeVerifier: codeVerifier
        };

        StorageManager.set(CONFIG.AUTHORIZATION_ATTRIBUTE, authorizationObject);

        window.location.replace(
            config.SESSION_API_URL + "bind_start/?code_challenge=" + codeChallenge
            + "&env=" + config.ENV
        );
    },

    /**
     * Handle binding response
     * @param otp
     * @returns {Promise<void>}
     */
    async acknowledge(otp) {
        let authObject = StorageManager.get(CONFIG.AUTHORIZATION_ATTRIBUTE);

        // Complete the authorization flow
        try {
            let response = await this.__completeBinding(otp, authObject.codeVerifier)
            StorageManager.delete(CONFIG.AUTHORIZATION_ATTRIBUTE);

            assert(response.headers["x-csrf-token"].length !== 0);

            SessionManager.setCSRFToken(response.headers["x-csrf-token"]);
        } catch (error) {
            StorageManager.delete(CONFIG.AUTHORIZATION_ATTRIBUTE);
            throw error;
        }
    },

    async __completeBinding(otp, codeVerifier) {
        const payload = {
            code_verifier: codeVerifier,
            otp: otp
        }

        return axios.post(config.SESSION_API_URL + "bind_ack/", payload, {withCredentials: true});
    }

}

export default BindingManager;