import {StorageManager} from "@/utils/storage";
import SessionAPI from "@/utils/api/session";
import store from "@/store";

const CONFIG = {
    CSRF_ATTRIBUTE: "x-csrf-token",
    ACTIVE_USER_ATTRIBUTE: "x-active-user",
}

const SessionErrors = Object.freeze({
    X_CSRF_TOKEN_INVALID: "X-CSRF-Token is invalid. Start binding process.",
    SESSION_INVALID: "Active session is invalid. Start binding process to retrieve a new one.",
    NO_ACTIVE_USER: "Could not get active user that is logged in",
})

const SessionManager = {

    accessToken: "",

    authenticated: false,

    ERRORS: SessionErrors,

    /**
     * Mega function that checks if a user is logged in the current browser session
     * @param preferredActiveUserID Optional ID of user that should be set as active user
     * @returns {Promise<boolean>}
     */
    async isLoggedIn(preferredActiveUserID = null) {
        if (this.authenticated) {
            return true;
        }

        if (this.getCSRFToken() == null) {
            console.error("No CSRF Token available");
            throw new Error(SessionErrors.X_CSRF_TOKEN_INVALID);
        }

        let storedActiveUserID = this.getStoredActiveUserID();

        let users = [];
        try {
            users = await store.dispatch("session/loadUsers")
        } catch (e) {
            console.log(e);
            return false;
        }

        if (users.length === 0) {
            return false;
        }

        let activeUserFound = null;

        // Check if the session's user list contains our preferred or stored active user
        activeUserFound = users.find(u => u.uuid === preferredActiveUserID);
        if (activeUserFound === undefined) {
            activeUserFound = users.find(u => u.uuid === storedActiveUserID);
        }

        // If we have not found the active user in browser session we can still set it if there's only one user stored in browser session
        if (activeUserFound === undefined) {
            if (users.length === 1) {
                activeUserFound = users[0];
                this.storeActiveUser(activeUserFound.uuid);
            } else {
                return false;
            }
        }

        // The active user found must be logged in
        if (!activeUserFound.logged_in) {
            return false;
        }

        store.commit("session/setActiveUser", activeUserFound);

        await this.refreshActiveUser();
        this.authenticated = true;

        return true;
    },

    userLoggedIn(user) {
        store.commit("session/setActiveUser", user);

        this.storeActiveUser(user.uuid);
        this.authenticated = true;

        this.refreshActiveUser().then(() => {});
    },

    /**
     * Returns active user ID for this browser session
     * @returns String
     */
    getStoredActiveUserID() {
        let userAttribute;

        try {
            userAttribute = StorageManager.get(CONFIG.ACTIVE_USER_ATTRIBUTE);
        } catch (e) {
            return null;
        }

        if (Object.prototype.hasOwnProperty.call(!userAttribute, "id")) {
            return null;
        }

        return userAttribute.id;
    },

    storeActiveUser(userID) {
        let activeUserObject = {
            id: userID
        }

        StorageManager.set(CONFIG.ACTIVE_USER_ATTRIBUTE, activeUserObject);
    },

    getCSRFToken() {
        let csrfAttribute;

        try {
            csrfAttribute = StorageManager.get(CONFIG.CSRF_ATTRIBUTE);
        } catch (e) {
            return null;
        }

        if (Object.prototype.hasOwnProperty.call(!csrfAttribute, "token")) {
            return null;
        }

        return csrfAttribute.token;
    },

    async logout () {
        let activeUserID = store.state.session.activeUser.uuid;

        await SessionAPI.logout(activeUserID);

        store.commit("session/setActiveUser", null);
        this.authenticated = false;
    },

    async refreshActiveUser() {
        let activeUserID = store.state.session.activeUser.uuid;

        let response = await SessionAPI.getAccessToken(activeUserID);

        this.accessToken = response.access_token;
    },

    setCSRFToken(value) {
        let tokenObject = {
            token: value
        };

        StorageManager.set(CONFIG.CSRF_ATTRIBUTE, tokenObject);
    },



}

export default SessionManager;