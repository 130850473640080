<template>

    <v-dialog
        v-model="dialog"
        persistent
        max-width="600">

        <v-card
            class="pa-0">

                <v-alert
                    class="mb-0"
                    icon="mdi-cloud-alert"
                    text
                    type="error"
                    prominent
                >
                    <h3 class="headline">Service Unavailable</h3>

                    <v-divider></v-divider>

                    <span>It seems like our service is currently unavailable.
                    We are very sorry for this. Please try again later.</span>

                    <v-row no-gutters>
                        <v-spacer></v-spacer>
                        <v-btn
                            @click="onTryAgain"
                            color="error"
                            outlined
                        >Try again</v-btn>
                    </v-row>

                </v-alert>

        </v-card>

    </v-dialog>

</template>

<script>

export default {
    name: "ServiceUnavailableDialog",

    data: () => ({
        dialog: true,
    }),

    methods: {

        onTryAgain() {
            this.$router.replace("/");
        },
    },
}
</script>

<style scoped>

</style>